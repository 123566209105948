import '../../styles/pages/fall-winter-2024/titan-builds-on-northgate-markets-success.scss';
import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../components/fall-winter-2024-stories/more-stories"
import Gallery from "react-photo-gallery";
import Vimeo from '@u-wave/react-vimeo';
import { Parallax, Background } from 'react-parallax';

import Hero from '../../images/fall-winter-2024/titan-builds-on-northgate-markets-success/story3-hero-1920x1080.jpg';
import socialBanner from "../../images/fall-winter-2024/social-banners/story3-social-1200x630.jpg";
import titelGraphic from "../../images/fall-winter-2024/titan-builds-on-northgate-markets-success/story3-title-380x245.svg";
import ctaBanner from '../../images/fall-winter-2024/titan-builds-on-northgate-markets-success/story3-cta-1920x515.jpg';


import northgateIMG1 from '../../images/fall-winter-2024/titan-builds-on-northgate-markets-success/story3-01-533x800.jpg';
import northgateIMG2 from '../../images/fall-winter-2024/titan-builds-on-northgate-markets-success/story3-01-800x533.jpg';
import northgateIMG3 from '../../images/fall-winter-2024/titan-builds-on-northgate-markets-success/story3-02-533x800.jpg';
import northgateIMG5 from '../../images/fall-winter-2024/titan-builds-on-northgate-markets-success/story3-03-800x533.jpg';
import northgateIMG6 from '../../images/fall-winter-2024/titan-builds-on-northgate-markets-success/story3-01-3815x1355.jpg';
import northgateIMG7 from '../../images/fall-winter-2024/titan-builds-on-northgate-markets-success/story3-02-3815x1355.jpg';

import heroVideoMP4 from '../../video/fall-winter-2024/northgate/northgate-hero.mp4';
import heroVideoWEBM from '../../video/fall-winter-2024/northgate/northgate-hero.webm';
import heroVideoVTT from 'file-loader!../../video/fall-winter-2024/northgate/northgate-hero.vtt';


export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    var pageTitle = 'Titan Builds on Northgate Market’s Success';
    var pageDesc = 'Third-generation family member and business alum Joshua González is a leader of the Northgate Market chain.';
    var slug = 'titan-builds-on-northgate-markets-success';

    var heroStyles = {
      backgroundImage: `url(${Hero})`,
    }

    const photos = [
      {
        src: northgateIMG1,
        width: 533,
        height: 800,
        alt: '',
        title: ''
      },
      {
        src: northgateIMG2,
        width: 800,
        height: 533,
        alt: '',
        title: ''
      },
      {
        src: northgateIMG3,
        width: 533,
        height: 800,
        alt: '',
        title: ''
      },
      {
        src: northgateIMG5,
        width: 800,
        height: 533,
        alt: '',
        title: ''
      },
    ];

    return (
      <Layout headerStyle="white" locationInfo={this.props.location} issue="fall-winter-2024">
        <Helmet>
          <title>{pageTitle}</title>
          <body className={`fall-winter-2024 ${slug}`} />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>
        
        <div className="hero story animated fadeIn" style={heroStyles}>
          <div className="text">
            <h1 className="animated fadeInDown delay-1s"><img className='titelGraphic shadow' src={titelGraphic} alt='Titan Builds on Northgate Market’s Success' /></h1>
            <p className="sub-heading animated fadeInDown delay-1s">Business alum Joshua González is a leader in the family-owned Mexican supermarket chain.
</p>
            <span className="animated fadeInDown delay-1s byline">By Debra Cano Ramos, Photos by Matt Gush </span>
          </div>
          <div className="tint"></div>
          <video id="heroVideo" autoPlay="autoplay" loop muted="muted" playsInline >
            <source src={heroVideoWEBM} type="video/webm; codecs=vp9,vorbis" />
            <source src={heroVideoMP4} />
            <track kind="captions" srcLang="en" label="English" src={heroVideoVTT} />
          </video>
        </div>
        <article className='wrap' id="copy-wrap">

          <div className='wrap small'>

    


{/* <figure className='align-left large align-outside'>
      <img src={northgateIMG4} alt="" />
      <figcaption>Caption Needed</figcaption>
</figure> */}



<p>Inside the bustling Mercado González Northgate Market, business alum Joshua González greets employees in Spanish and stops to chat  with shoppers, pushing carts filled with freshly made Sonora-style tortillas, salsa and Mexican staples.</p>

<p>The sprawling 70,000-square-foot <a href="https://www.northgatemarket.com/mercado" title="mercado" target="_blank">mercado</a> in Costa Mesa has colorful murals on the walls, imported products from Mexico, a carnicería packed with fresh meats, a tortillería, a bakery and puestos, or food stands,  with authentic made-to-order Mexican dishes and desserts.</p>

<figure className='align-wide'>
      <img src={northgateIMG2} alt="" />
      <figcaption>Business alum Joshua González inside his family’s Mercado González Northgate Market in Costa Mesa</figcaption>
</figure>

<p>&ldquo;Mercado González is like walking through a vibrant marketplace in Mexico,&rdquo; says González, general manager of Mercado González and business development. &ldquo;We bring Mexican experiences to everyone. We wanted a market where people of all walks of life and cultures can interact in one place.&rdquo;</p>

<blockquote className='align-right large align-outside'>
  <p>Cal State Fullerton gave me so much support with its dedication to educating first-generation students.<span className='left-quote'>”</span></p>
  <cite>Joshua González ’18 (B.A. business administration) </cite>
</blockquote>

<p>As a third-generation family member, González grew up in the family-owned Northgate Market business, founded 44 years ago by his late  grandfather Don Miguel González Jiménez, who opened his first market near downtown Anaheim.</p>

<p>An immigrant from Jalostotitlán, Jalisco, a small town in central Mexico, the family patriarch left his homeland in pursuit of his dream  to build a better life for his wife, Doña Teresa Reynoso, and their 13 children.</p>


<p>The 2018 Cal State Fullerton business administration  graduate, along with his family and team, spearheaded the development of the  flagship store to reimagine the traditional Mexican grocery store industry.</p>



<p>Mercado González employs 600 employees and 21 <a href="https://www.northgatemarket.com/mercado/puestos" title="puestos" target="_blank">puestos</a> sell aguas  frescas, churros, tamales, street tacos and more. The innovative grocery store  concept opened last fall and attracts locals and multicultural customers from  around the world.</p>

<figure className='align-right large align-outside'>
      <img src={northgateIMG5} alt="" />
      <figcaption>Mercado González features the flavors and foods of Mexico, including a variety of beans and chile peppers</figcaption>
</figure>

<h2>A Role Model for &lsquo;What Is Possible&rsquo;</h2>
<p>González, a first-generation university graduate, chose Cal  State Fullerton because of the <a href="https://www.fullerton.edu/business-economics/" title="College of Business and Economics’" target="_blank">College of Business and Economics&rsquo;</a> top-tier reputation and to hone his skill set to contribute to  the family business. He often enrolled in evening classes to work during the  day at Northgate Market.</p>

</div>
</article>
<Parallax
          bgImage={northgateIMG6}
          bgImageAlt=""
          strength={200}
          style={{margin: 0}}
          className='full-width'>
            <div className="caption">
            Joshua González, general manager of Mercado González and business development
            </div>
            <div className='height' />
</Parallax>
<Parallax
          bgImage={northgateIMG7}
          bgImageAlt=""
          strength={200}
          style={{margin: 0}}
          className='full-width'>
            <div className="caption">
            The family-owned supermarket boasts puestos, or food stands, that sell refreshing aguas frescas to Mexican dishes and fresh-made tortillas, imported foods, a carnicería and more
            </div>
            <div className='height' />
</Parallax>

<article className='wrap' id="copy-wrap-no-margin">

<div className='wrap small' style={{marginTop: '2em'}}>


<p>&ldquo;Cal State Fullerton gave me so much support with its  dedication to educating first-generation students and uplifting communities,&rdquo;  says González, adding that nine other family members also have earned CSUF  business degrees.</p>

<figure className='align-left small align-outside'>
      <img src={northgateIMG1} alt="" />
      <figcaption>Mercado González features made-to-order churros from Churrería El Moro, founded in Mexico City in 1935</figcaption>
</figure>

<p>Earlier this year, González was invited to join the  college&rsquo;s <a href="https://business.fullerton.edu/about/deans-advisory-board" title="Dean’s Advisory Board" target="_blank">Dean&rsquo;s  Advisory Board</a> to ensure all students receive the support they need to  succeed academically and professionally in their business careers. He also is  serving as co-chair of the college&rsquo;s new Alumni Council.</p>



<p>&ldquo;Joshua is a great role model for all our students in the  college,&rdquo; says Sridhar Sundaram, dean of the College of Business and Economics.  &ldquo;As a first-generation college graduate whose family migrated from Mexico with  humble beginnings, his accomplishments are a great example of what is possible  for our students.&rdquo;</p>
<p>González, who studied finance, has turned to the college&rsquo;s <a href="https://business.fullerton.edu/engagement/family-business" title="Center for Family Business" target="_blank">Center for  Family Business</a> for insights on family governance, risk management and new  technology.</p>



<p>&ldquo;Through my involvement with the center, I&rsquo;ve had access to  families who think the same about succession and multigenerational businesses,&rdquo;  he says. &ldquo;Part of our aspiration as a family and a business is to be a 100-year  evergreen company, meaning it regenerates over time to ensure that it is kept  privately held in our family.&rdquo;</p>

<blockquote className='align-right large align-outside'>
  <p>Joshua’s accomplishments are a great example of what is possible for our students.<span className='left-quote'>”</span></p>
  <cite>Sridhar Sundaram, dean of the College of Business and Economics</cite>
</blockquote>

<p>In keeping with their grandparents&rsquo; legacy of giving back to  the communities they serve, González and his family have financially supported  university programs and activities. Their contributions have benefited the  Center for Family Business, Giles-O&rsquo;Malley Center for Leadership, Division of  Student Affairs, and College of Engineering and Computer Science.</p>


<p>González has also helped to raise funds for Abrego Future  Scholars, which provides underrepresented first-generation college students  with academic and financial support.&nbsp;</p>
<p>&ldquo;My family believes in Cal State Fullerton&rsquo;s mission to  educate young people to change the trajectory of their lives and their  families,&rdquo; he says.</p>
<h2>From Humble Beginnings</h2>



<p>In González&rsquo;s speech to Class of 2024 grads at the College  of Business and Economics&rsquo; commencement ceremony, González shared his family&rsquo;s  history and recounted their pursuit of the American dream.</p>

<figure className='align-left large align-outside'>
      <img src={northgateIMG3} alt="" />
      <figcaption>Joshua González poses in front of one of the store’s eye-catching and colorful murals that express the Mexican culture </figcaption>
</figure>

<p>After a tragic fire burned Don Miguel&rsquo;s La Elegancia shoe  factory and store in Jalostotitlán, he traveled north in the mid-1960s to the  United States.</p>



<p>González&rsquo;s grandfather bought the Anaheim Northgate liquor  store in 1980 and converted the 2,000-square-foot building into a neighborhood  grocery store.</p>



<p>Today, the close-knit family, who get together for a weekly  lunch to stay united, operates 43 markets across Southern California and has  over 7,500 employees. Of the 53 grandchildren, 32 work in the family business,  along with five great-grandchildren.</p>

<p>González works alongside his eight uncles, five aunts and  scores of cousins, nephews and nieces to build the family&rsquo;s Northgate Market  brand and deliver quality Mexican food and products at a great value.</p>
<p>After graduating from CSUF, González went on to earn a  master&rsquo;s degree in real estate development from USC and is a leader in the  company&rsquo;s expansion. Multiple new Northgate Markets are under construction and  the family plans to open more stores over the next decade, including a second  Mercado González.</p>
<p>&ldquo;We&rsquo;re a faith-based family, and that&rsquo;s always at the center  of what we do. We believe this business is a gift,&rdquo; González says. &ldquo;Our family  has a framework of gratitude and excitement for the future in what we can  continue to create for the people and communities we serve.&rdquo; <span className="end-mark" /></p>


    


          
          </div>
        </article>
        <div className='cta-banner' style={{ backgroundImage: `url(${ctaBanner})` }}>
          <div className='cta-text'>
            <p>
              <span className='cta-title'>Support the College of Business and Economics</span><br />
              <a href="https://give.fullerton.edu/titanmag-cbe" target='_blank' rel='noreferrer'>give.fullerton.edu/titanmag-cbe</a>
            </p> 
          </div>
        </div>
        <MoreStories slug={slug} />
      </Layout>
    );
  }
} 